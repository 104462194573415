<template>
  <form @submit="onSubmit">
    <form-textarea
          name="message"
          :label="$t('project', 'Комментарий')"
          :error-message="form.errors.message"
          :rows="3"
          @change="onChange('message', $event)"
          @input="onInput('message', $event)"></form-textarea>

    <b-button type="submit" variant="primary">{{ $t('project', 'Вернуть спецификацию') }}</b-button>
  </form>
</template>

<script>

import { FormTextarea } from '@/components'
import { FormMixin } from '@/mixins'
import { required } from '@/validators'
export default {
  name: 'cancel-form',
  mixins: [ FormMixin ],
  components: { FormTextarea },
  props: [ 'model', 'file' ],
  data () {
    return {
      form: {
        model: {
          message: null,
          status: this.model.designer ? 'designing' : 'sent',
          specification_id: this.file.id
        },
        service: 'customer/projects/' + this.model.id + '/status',
        serviceMethod: 'patch',
        afterSave: (data) => {
          this.$notifySuccess(this.$t('project', 'Спецификация возвращена на доработку.'))
          this.$emit('specification-returned', [
            { attribute: 'status', value: data.status },
            { attribute: 'specifications', value: data.specifications }
          ])
        }
      }
    }
  },
  validations () {
    return {
      form: {
        model: {
          message: {
            required
          }
        }
      }
    }
  }
}
</script>

<template>
  <router-view v-if="isAuthenticated"></router-view>
</template>

<script>
import { mapState, mapMutations, mapGetters } from 'vuex'
import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

export default {
  name: 'app',
  computed: {
    ...mapState('user', {
      isAuthenticated: state => state.isAuthenticated,
      isCustomer: state => {
        return state.role === 'customer'
      }
    }),
    ...mapState('app', {
      googleApiKey: state => state.googleApiKey
    }),
    ...mapGetters('user', [
      'isInitialized'
    ])
  },
  created () {
    // check if user authenticated
    this.initUser()
    // add translation function
    Vue.prototype.$t = (category, string) => this.$store.getters['page/translate'](category, string)
    // reset Page state on each router navigating
    this.$router.afterEach((to, from) => {
      this.resetPageTitle()
    })
  },
  methods: {
    ...mapMutations({
      resetPageTitle: 'page/resetTitle'
    }),
    async initUser () {
      try {
        await this.$store.dispatch('user/init')
        if (!this.isCustomer) {
          window.location.href = this.$const.LOGIN_URL
        }
        // redirect to init form in profile if user not initialized
        if (!this.isInitialized) {
          this.$router.push({ name: 'profile' })
        }
        await this.initTranslations()
      } catch (e) {
        this.$notifyError(this.$t('user', 'Не удалось идентифицировать пользователя.') + ' ' + e.message)
      }
    },
    async initTranslations () {
      try {
        // add translations
        await this.$store.dispatch('page/attachTranslations')
      } catch (e) {
        this.$notifyWarning(this.$t('user', 'Не удалось загрузить файл перевода.') + ' ' + e.message)
      }
    }
  },
  watch: {
    isAuthenticated: function (isAuthenticated) {
      if (!isAuthenticated) {
        window.location.href = this.$const.LOGIN_URL
      }
    },
    googleApiKey: function (key) {
      if (key) {
        Vue.use(VueGoogleMaps, {
          load: {
            key,
            libraries: 'drawing,places',
            language: 'uk'
          }
        })
      }
    }
  }
}
</script>

<style lang="scss">
  // Import Main styles for this application
  @import 'assets/scss/style';
</style>

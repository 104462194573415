export default {
  items: [
    // {
    //   name: 'Dashboard',
    //   url: '/dashboard',
    //   icon: 'users'
    // },
    {
      name: 'Проекты',
      url: { name: 'projects' },
      icon: 'cubes'
    },
    {
      name: 'Менеджер',
      url: { name: 'manager' },
      icon: 'user-tie'
    }
  ]
}

<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-header">
        {{ $t('user', 'Данные вашего менеджера') }}
      </div>
      <div class="card-body">
        <p v-if="!manager" class="text-muted mb-1">
          {{ $t('user', 'На данный момент ваш менеджер еще не назначен.') }}
        </p>
        <dl v-else class="row">
          <dt class="col-sm-3">{{ $t('user', 'Имя') }}</dt>
          <dd class="col-sm-9">{{ managerName }}</dd>

          <dt class="col-sm-3">{{ $t('user', 'Email адрес') }}</dt>
          <dd class="col-sm-9">{{ manager.email }}</dd>

          <dt v-if="manager.phone" class="col-sm-3">{{ $t('user', 'Рабочий телефон') }}</dt>
          <dd v-if="manager.phone" class="col-sm-9">{{ manager.phone }}</dd>

          <dt v-if="manager.mobile_phone" class="col-sm-3">{{ $t('user', 'Мобильный телефон') }}</dt>
          <dd v-if="manager.mobile_phone" class="col-sm-9">{{ manager.mobile_phone }}</dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'manager',
  computed: {
    manager () {
      return this.$store.state.user.manager
    },
    managerName () {
      return this.manager ? (
        (this.manager.first_name || '') +
        ' ' +
        (this.manager.middle_name || '') +
        ' ' +
        this.manager.last_name
      ).trim() : ''
    }
  }
}
</script>

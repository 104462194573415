<template>
  <div class="app">
    <AppHeader fixed>
      <SidebarToggler class="d-lg-none" display="md" mobile />
      <!-- <b-link class="navbar-brand" to="#">
        <img class="navbar-brand-full" src="img/brand/logo.svg" width="89" height="25" alt="CoreUI Logo">
        <img class="navbar-brand-minimized" src="img/brand/sygnet.svg" width="30" height="30" alt="CoreUI Logo">
      </b-link> -->
      <SidebarToggler class="d-md-down-none" display="lg" />
      <HeaderNav></HeaderNav>
    </AppHeader>
    <div class="app-body">
      <notifications />
      <div v-if="has_business_error && name !== 'profile'" class="has_error_card">
        <div class="alert alert-warning m-0 alert-dismissible">
          <button
            type="button"
            @click="() => { has_business_error = false }"
            class="close"
            data-dismiss="alert"
            aria-label="Close">
            <span aria-hidden="true">×</span>
          </button>
          <p>
            <span class="text-danger">!!!</span>
            <span class="font-weight-bold">{{ $t('service', 'С целью улучшения предоставления услуг необходимо подтвердить Ваши контактные данные.') }}</span>
          </p>
          <p>
            {{ $t('user', 'Пожалуйста перейдите в') }} <router-link :to="{ name: 'profile'}">{{ $t('user', 'Ваш профиль') }}</router-link> {{ $t('user', 'и заполните недостающую информацию.') }}
          </p>
        </div>

      </div>

      <AppSidebar fixed>
        <SidebarHeader/>
        <SidebarForm/>
        <SidebarNav :navItems="translateNav"></SidebarNav>
        <SidebarFooter/>
        <SidebarMinimizer/>
      </AppSidebar>
      <main class="main">
        <Breadcrumb :list="list"/>
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
    </div>
    <TheFooter>
      <!--footer-->
      <div>
        <span>CCTV &#38; AC &copy; 2018-2023</span>
      </div>
      <div class="ml-auto">
        <span>Developed by</span>
        <a type="button">
          <dev-logo></dev-logo>
        </a>
      </div>
    </TheFooter>
  </div>
</template>

<script>
import { nav } from '@/config'
import { Header as AppHeader, SidebarToggler, Sidebar as AppSidebar, SidebarFooter, SidebarForm, SidebarHeader, SidebarMinimizer, Footer as TheFooter, Breadcrumb } from '@coreui/vue'
import { DevLogo } from '@/components'
import SidebarNav from './SidebarNav'
import HeaderNav from './HeaderNav'

export default {
  name: 'DefaultContainer',
  components: {
    AppHeader,
    HeaderNav,
    AppSidebar,
    TheFooter,
    Breadcrumb,
    SidebarForm,
    SidebarFooter,
    SidebarToggler,
    SidebarHeader,
    SidebarNav,
    SidebarMinimizer,
    DevLogo
  },
  data () {
    return {
      nav: nav.items,
      has_business_error: false
    }
  },
  created () {
    this.has_business_error = !this.$store.state.user.business_id
  },
  watch: {
    business_info_completed (value) {
      if (value) {
        this.has_business_error = false
      }
    }
  },
  computed: {
    name () {
      return this.$route.name
    },
    business_info_completed () {
      return this.$store.state.user.business_id
    },
    list () {
      const result = []
      const list = this.$route.matched.filter((route) => (route.name || route.meta.label) && route.meta.hideBreadcrumb !== true)
      list.forEach((el) => {
        if (el.name) {
          el.name = this.$t('project', el.name)
        }
        if (el.meta.label) {
          el.meta.label = this.$t('project', el.meta.label)
        }
        result.push(el)
      })
      return result
    },
    companyPopup () {
      return this.$store.state.user.business_id
    },
    translateNav () {
      const result = []
      this.nav.forEach((el) => {
        el.name = this.$t('project', el.name)
        result.push(el)
      })
      return result
    }
  }
}
</script>
<style>
.has_error_card{
  position: fixed;
  bottom: 50px;
  right: 0;
  z-index: 1;
  margin: 20px;
}
</style>

<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-lg-7">
        <!-- Main form -->
        <div class="card">
          <div class="card-header">
            {{ $t('user', 'Ваш профиль') }}
          </div>
          <div class="card-body">
            <p v-if="!userInitialized" class="text-muted">
              <span class="text-danger">!!!</span>
              {{ $t('user', 'Прежде чем получить доступ ко всем возможностям приложения, пожалуйста, заполните регистрационную форму.') }}
            </p>
            <form @submit="onSubmit($event)">
              <form-input
                name="first_name"
                :label="$t('user', 'Имя')"
                :value="form.model.first_name"
                :required="!!$v.form.model.first_name && !!$v.form.model.first_name.$params.required"
                :isValid="form.saved.first_name"
                :error-message="form.errors.first_name"
                @change="onChange('first_name', $event)"
                @input="onInput('first_name', $event)"></form-input>

              <form-input
                name="middle_name"
                :label="$t('user', 'Отчество')"
                :value="form.model.middle_name"
                :required="!!$v.form.model.middle_name && !!$v.form.model.middle_name.$params.required"
                :isValid="form.saved.middle_name"
                :error-message="form.errors.middle_name"
                @change="onChange('middle_name', $event)"
                @input="onInput('middle_name', $event)"></form-input>

              <form-input
                name="last_name"
                :label="$t('user', 'Фамилия')"
                :required="!!$v.form.model.last_name && !!$v.form.model.last_name.$params.required"
                :value="form.model.last_name"
                :isValid="form.saved.last_name"
                :error-message="form.errors.last_name"
                @change="onChange('last_name', $event)"
                @input="onInput('last_name', $event)"></form-input>
              <form-input
                name="company"
                :label="$t('user', 'Компания')"
                :required="!!$v.form.model.company && !!$v.form.model.company.$params.required"
                :value="form.model.company"
                :isValid="form.saved.company"
                :error-message="form.errors.company"
                @change="onChange('company', $event)"
                @input="onInput('company', $event)"></form-input>

              <b-form-group v-slot="{ ariaDescribedby }">
                <b-form-radio-group
                  v-model="form.model.business_type"
                  :options="form.business_options"
                  :aria-describedby="ariaDescribedby"
                  name="business_type"
                ></b-form-radio-group>
              </b-form-group>

              <form-input
                name="business_id"
                :label="form.model.business_type === 'company' ? $t('user', 'ЕГРПОУ') : $t('user', 'ИНН')"
                :value="form.model.business_id"
                :required="!!$v.form.model.business_id && !!$v.form.model.business_id.$params.required"
                :isValid="form.saved.business_id"
                :error-message="form.errors.business_id"
                @change="onChange('business_id', $event)"
                @input="onInput('business_id', $event)"></form-input>
              <form-input
                name="website"
                :label="$t('user', 'Сайт Компании')"
                :value="form.model.website"
                :required="!!$v.form.model.website && !!$v.form.model.website.$params.required"
                :isValid="form.saved.website"
                :error-message="form.errors.website"
                @change="onChange('website', $event)"
                @input="onInput('website', $event)"></form-input>
              <form-input
                name="address"
                :label="$t('user', 'Адреса')"
                :isValid="form.saved.address"
                :value="form.model.address"
                :required="!!$v.form.model.address && !!$v.form.model.address.$params.required"
                :error-message="form.errors.address"
                @change="onChange('address', $event)"
                @input="onInput('address', $event)"></form-input>

              <form-masked
                name="phone"
                :label="$t('user', 'Номер рабочего телефона')"
                :value="form.model.phone"
                :required="!!$v.form.model.phone && !!$v.form.model.phone.$params.required"
                type="tel"
                :isValid="form.saved.phone"
                :error-message="form.errors.phone"
                @change="onChange('phone', $event)"
                @input="onInput('phone', $event)"
              ></form-masked>
              <div class="row">
                <div class="col-12 col-sm-4">
                  <form-masked
                    name="mobile_phone"
                    :label="$t('user', 'Номер мобильного телефона')"
                    :disabled="form.phoneVerification.state === 'enterTheCode'"
                    :value="form.model.mobile_phone"
                    :required="!!$v.form.model.mobile_phone && !!$v.form.model.mobile_phone.$params.required"
                    type="tel"
                    :isValid="form.saved.mobile_phone && form.mobile_phone_verified"
                    :error-message="form.errors.mobile_phone || form.errors.mobile_phone_verified"
                    @change="onChange('mobile_phone', $event)"
                    @input="onInput('mobile_phone', $event)"
                  ></form-masked>
                </div>
                <div class="col-12 col-sm-8 align-self-end"
                     v-if="form.model.mobile_phone
                     && /^\+38 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(form.model.mobile_phone)">
                  <template>
                    <template v-if="$store.state.user.mobile_phone_verified && $store.state.user.mobile_phone === form.model.mobile_phone">
                      <div class="form-group align-self-end">
                        <svg width="30" height="30" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_204_84)">
                            <path fill="#46d46a" d="M186.662 26.6703C183.125 26.6716 179.733 28.0772 177.233 30.5778L90.0287 117.782L68.679 75.7653C63.472 70.5584 55.0273 70.5559 49.8172 75.7597C44.6072 80.9636 44.6047 89.4032 49.8116 94.6101L80.5951 146.06C85.8046 151.269 94.2529 151.269 99.4624 146.06L196.1 49.4226C201.308 44.2156 201.305 35.7755 196.095 30.5716C193.593 28.0725 190.2 26.6692 186.662 26.6703ZM98.9872 -0.0103897C73.4462 0.262358 48.2412 10.2784 29.2647 29.2606C-2.69559 61.2306 -9.22454 110.847 13.3728 149.999C35.9701 189.15 82.2107 208.313 125.876 196.609C169.541 184.906 200 145.192 200 99.9881C200 92.6266 194.029 86.659 186.663 86.6589C179.297 86.6586 173.325 92.6264 173.325 99.9881C173.325 133.266 151.086 162.251 118.966 170.861C86.8463 179.47 53.1109 165.495 36.4789 136.679C19.847 107.863 24.6169 71.6284 48.1352 48.103C71.6535 24.5775 107.852 19.8115 136.652 36.4445C143.03 40.1277 151.188 37.9463 154.874 31.5723C158.559 25.1986 156.376 17.0461 149.999 13.3628C134.096 4.17819 116.463 -0.197007 98.9872 -0.0103897Z" />
                          </g>
                        </svg>
                      </div>
                    </template>
                    <template v-else>
                      <template v-if="form.phoneVerification.state === 'initial'">
                        <div class="form-group align-self-end">
                          <button @click="verifyNextStep"
                                  type="button"
                                  class="btn btn-success align-self-end ">
                            {{ $t('service', 'Верификация') }}
                          </button>
                        </div>

                      </template>
                      <template v-else-if="form.phoneVerification.state === 'askToVerify'">
                        <div class="form-group ask_sms_desk d-flex flex-column justify-content-between">
                          <p class="mb-0">
                            {{ $t('service', 'С целью подтверждения мобильного номера мы отправим СМС на номер') }}
                            <span class="phone_span">{{form.model.mobile_phone}}</span>
                          </p>
                          <button @click="verifyNextStep"
                                  type="button"
                                  class="btn btn-success ml-auto"
                                  >
                        <span v-if="form.phoneVerification.buttonReloading"
                              class="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"></span>
                            Отправить
                          </button>
                        </div>
                      </template>
                      <template v-else-if="form.phoneVerification.state === 'enterTheCode'">
                        <div class="">
                          <b-form-group
                            :invalid-feedback="form.errors.code"
                            :state="form.saved.code">
                            <label :for="'code_0'">{{ $t('service', 'Введите 4 - значный код полученный по СМС') }} <span v-if="numberToTime">({{numberToTime}})</span></label>
                            <div class="d-flex">
                              <div>
                                <code-input
                                  name="code"
                                  @change="codeChanged"
                                  :fields="4"
                                  :disabled="form.phoneVerification.outOfLimit"
                                  :fieldWidth="34"
                                  :fieldHeight="34"
                                  :loading="false"
                                ></code-input>
                              </div>

                              <div class="ml-2">
                                <button
                                  type="button"
                                  @click="verifyNextStep"
                                  class="btn btn-success"
                                  :disabled="form.phoneVerification.buttonDisabled || form.phoneVerification.outOfLimit">
                                <span v-if="form.phoneVerification.buttonReloading"
                                      class="spinner-border spinner-border-sm"
                                      role="status"
                                      aria-hidden="true"></span>
                                  {{ $t('service', 'Подтвердить') }}
                                </button>
                                <div>
                                  <button type="button" :disabled="!form.phoneVerification.resendValid" @click="sendAgainClicked" class="send_again_link">{{ $t('service', 'отправить заново') }}</button>
                                </div>
                              </div>
                            </div>

                          </b-form-group>
                        </div>

                      </template>
                    </template>
                  </template>

                </div>

              </div>

              <form-textarea
                v-if="!userInitialized"
                name="manager_data"
                :label="$t('user', 'Информация о своем менеджере')"
                :value="form.model.manager_data"
                :isValid="form.saved.manager_data"
                :error-message="form.errors.manager_data"
                @change="onChange('manager_data', $event)"
                @input="onInput('manager_data', $event)"></form-textarea>
              <datalist id="my-list-id">
                <option>Manual Option</option>
                <option v-for="(size,index) in sizes" :key="index">{{ size }}</option>
              </datalist>
              <button type="submit" class="btn btn-primary">{{ $t('service', 'Сохранить') }}</button>
            </form>
          </div>
        </div>
      </div>

      <div v-if="userInitialized" class="col-lg-5">
        <!-- Email update -->
        <div class="card">
          <div class="card-header">
            {{ $t('user', 'Изменение пароля') }}
          </div>
          <div class="card-body">
            <update-password-form></update-password-form>
          </div>
        </div>

        <!-- Password update -->
        <div class="card">
          <div class="card-header">
            {{ $t('user', 'Изменение электронного адреса') }}
          </div>
          <div class="card-body">
            <update-email-form></update-email-form>
          </div>
        </div>

        <!-- Lang update -->
        <div class="card">
          <div class="card-header">
            {{ $t('user', 'Изменение языка') }}
          </div>
          <div class="card-body">
            <update-lang-form></update-lang-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { FormInput, FormRadio, FormMasked, FormTextarea, CodeInput } from '@/components'
import UpdatePasswordForm from './profile/_updatePasswordForm'
import UpdateEmailForm from './profile/_updateEmailForm'
import UpdateLangForm from './profile/_updateLangForm'
import { required, maxLength, numeric } from '@/validators'
import { FormMixin } from '@/mixins'
import { TheMask } from 'vue-the-mask'
export default {
  name: 'profile',
  mixins: [ FormMixin ],
  components: {
    FormMasked,
    CodeInput,
    FormInput,
    TheMask,
    FormRadio,
    FormTextarea,
    UpdatePasswordForm,
    UpdateEmailForm,
    UpdateLangForm
  },
  data () {
    const d = {
      sizes: ['Small', 'Medium', 'Large', 'Extra Large'],
      timeouts: {},
      verifiedSign: "<div style='color : #3ea662'><i class='fa verified fa-square-check'></i></div>",
      form: {
        model: {
          first_name: this.$store.state.user.first_name,
          middle_name: this.$store.state.user.middle_name,
          last_name: this.$store.state.user.last_name,
          company: this.$store.state.user.company,
          phone: this.$store.state.user.phone,
          mobile_phone: this.$store.state.user.mobile_phone,
          mobile_phone_verified: this.$store.state.user.mobile_phone_verified,
          business_type: this.$store.state.user.business_type || 'company',
          business_id: this.$store.state.user.business_id,
          address: this.$store.state.user.address,
          website: this.$store.state.user.website
        },
        phoneVerification: {
          timeout: null,
          remaining: null,
          state: this.$store.state.user.verification_code_sent ? 'enterTheCode' : 'initial',
          code: '',
          outOfLimit: false,
          buttonDisabled: false,
          buttonReloading: false,
          config: this.$store.state.user.verification_config,
          resendValid: false
        },
        business_options: [
          { text: this.$t('user', 'Компания'), value: 'company' },
          { text: this.$t('user', 'Частный предприниматель'), value: 'private' }
        ],
        service: 'profile',
        serviceMethod: 'patch',
        afterSave: (data) => {
          this.profileUpdated(data)
        }
      }
    }
    if (!this.userInitialized) {
      d.form.model.manager_data = null
    }
    return d
  },
  watch: {
    'form.model.mobile_phone': function () {
      if (this.$store.state.user.mobile_phone_verified) {
        this.form.model.mobile_phone_verified = (this.$store.state.user.mobile_phone === this.form.model.mobile_phone)
      }
    }
  },
  mounted () {
    if (this.form.phoneVerification.config) {
      this.checkOutOfLimit(true)
    }
  },
  methods: {
    verifyNextStep () {
      switch (this.form.phoneVerification.state) {
        case 'initial':
          this.form.phoneVerification.state = 'askToVerify'
          break
        case 'askToVerify':
          this.sendTheCode()
          break
        case 'enterTheCode':
          this.verifyCode()
          break
      }
    },
    sendTheCode () {
      this.disableButton(true)
      this.$http.request({
        url: 'profile/send-code',
        method: 'patch',
        data: { number: this.form.model.mobile_phone }
      }).then(response => {
        if (response.data.success === 'success') {
          this.enableButton()
          this.form.phoneVerification.config = response.data.config
          this.$store.commit('user/updateDetails', ['mobile_phone', response.data.number])
          this.$store.commit('user/updateDetails', ['mobile_phone_verified', false])
          let start = response.data.config.sms_sent
          let end = Math.floor(new Date().getTime() / 1000)
          this.form.phoneVerification.remaining = 60 + start - end
          this.startDynamicCountDown('verification',
            60 + start - end,
            () => { this.form.phoneVerification.remaining-- },
            () => {
              this.enableButton()
              this.form.phoneVerification.config.attempts = 0
              this.form.phoneVerification.remaining = 0
              this.form.phoneVerification.outOfLimit = false
              this.form.errors.code = ''
              this.form.saved.code = true
            }
          )
          this.startDynamicCountDown('resend',
            30 + start - end,
            () => {},
            () => {
              this.form.phoneVerification.resendValid = true
            }
          )
          this.form.phoneVerification.state = 'enterTheCode'
        }
      }).catch(e => {

      })
    },
    codeChanged (val) {
      this.form.phoneVerification.code = val
      this.form.saved.code = true
      this.form.errors.code = ''
    },
    startCountDown (time) {
      if (!this.timeout) {
        this.form.phoneVerification.remaining = time
        this.timeout = setInterval(() => {
          if (this.form.phoneVerification.remaining > 1) {
            this.form.phoneVerification.remaining--
          } else {
            this.form.phoneVerification.remaining--
            this.endCountDown()
          }
        }, 1000)
      }
    },
    startDynamicCountDown (name, time, func, afterCallback) {
      if (!this.timeouts[name]) {
        this.timeouts[name] = 'value'
        this.timeouts[name] = setInterval(() => {
          if (this.timeouts && this.timeouts[name]) {
            if (time > 1) {
              func()
              time--
            } else {
              time--
              this.endDynamicCountDown(name, afterCallback)
            }
          } else {
            this.endDynamicCountDown(name, afterCallback)
          }
        }, 1000)
      }
    },
    endDynamicCountDown (name, callback) {
      clearInterval(this.timeouts[name])
      this.timeouts[name] = null
      callback()
    },
    endCountDown () {
      clearInterval(this.timeout)
      this.timeout = null
      this.enableButton()
      this.form.phoneVerification.config.attempts = 0
      this.form.phoneVerification.outOfLimit = false
      this.form.errors.code = ''
      this.form.saved.code = true
    },
    sendAgainClicked () {
      this.$http.request({
        url: 'profile/reset-code',
        method: 'get'
      }).then(response => {
        if (response.data.success === 'success') {
          this.form.phoneVerification.remaining = null
          if (this.timeouts['verification']) {
            clearInterval(this.timeouts['verification'])
          }
          this.enableButton()
          this.form.phoneVerification.resendValid = false
          this.form.phoneVerification.outOfLimit = false
          this.timeouts['verification'] = null
          this.form.phoneVerification.state = 'askToVerify'
        }
      }).catch(e => {

      })
    },
    verifyPhone () {
      this.$notifySuccess(this.$t('user', 'Номер мобильного телефона верифицирован'))
      this.$store.commit('user/updateDetails', ['mobile_phone_verified', true])
      this.form.model.mobile_phone_verified = true
      this.form.errors.mobile_phone = ''
      this.form.phoneVerification.state = 'initial'
    },
    disableButton (loading = false) {
      this.form.phoneVerification.buttonDisabled = true
      if (loading) {
        this.form.phoneVerification.buttonReloading = true
      }
    },
    enableButton () {
      this.form.phoneVerification.buttonDisabled = false
      this.form.phoneVerification.buttonReloading = false
    },
    checkOutOfLimit (timeout = false) {
      if (this.form.phoneVerification.config) {
        let start = this.form.phoneVerification.config.sms_sent
        let end = Math.floor(new Date().getTime() / 1000)
        if (this.form.phoneVerification.config.attempts >= 3) {
          if (end - start >= 30) {
            this.form.phoneVerification.resendValid = true
          } else {
            this.startDynamicCountDown('resend',
              30 + start - end,
              () => {},
              () => {
                this.form.phoneVerification.resendValid = true
              }
            )
          }
          if (end - start >= 60) {
            this.form.phoneVerification.config.attempts = 0
            this.form.phoneVerification.outOfLimit = false
          } else {
            this.form.phoneVerification.remaining = 60 + start - end
            this.startDynamicCountDown('verification',
              60 + start - end,
              () => { this.form.phoneVerification.remaining-- },
              () => {
                this.enableButton()
                this.form.phoneVerification.config.attempts = 0
                this.form.phoneVerification.remaining = 0
                this.form.phoneVerification.outOfLimit = false
                this.form.errors.code = ''
                this.form.saved.code = true
              }
            )
            this.form.phoneVerification.outOfLimit = true
            this.disableButton()
            this.form.errors.code = this.$t('service', 'Вы превысили лимит попыток за минуту (3/3)')
            this.form.saved.code = false
          }
        } else {
          if (end - start >= 30) {
            this.form.phoneVerification.resendValid = true
          } else {
            this.startDynamicCountDown('resend',
              30 + start - end,
              () => {},
              () => {
                this.form.phoneVerification.resendValid = true
              }
            )
          }
          this.form.phoneVerification.remaining = 60 + start - end
          this.startDynamicCountDown('verification',
            60 + start - end,
            () => { this.form.phoneVerification.remaining-- },
            () => {
              this.enableButton()
              this.form.phoneVerification.config.attempts = 0
              this.form.phoneVerification.remaining = 0
              this.form.phoneVerification.outOfLimit = false
              this.form.errors.code = ''
              this.form.saved.code = true
            }
          )
        }
      }
    },
    verifyCode () {
      if (/^[0-9]{4}$/.test(this.form.phoneVerification.code)) {
        this.disableButton(true)
        this.$http.request({
          url: 'profile/verify-phone',
          method: 'patch',
          data: { code: this.form.phoneVerification.code }
        }).then(response => {
          if (response.data.success === 'success') {
            this.enableButton()
            this.verifyPhone()
          } else {
            this.form.phoneVerification.config = response.data.config
            this.enableButton()
            this.checkOutOfLimit()
            this.form.saved.code = false
            this.form.errors.code = response.data.message
          }
        }).catch(e => {
          this.form.saved.code = false
          this.form.errors.code = this.$t('service', 'Неизвестная ошибка')
        })
      } else {
        this.form.saved.code = false
        this.form.errors.code = this.$t('service', 'Код неправильного формата либо длины')
      }
    },
    resetVerifying () {
      this.form.phoneVerification.state = 'initial'
    },
    profileUpdated (data) {
      this.$notifySuccess(!this.userInitialized
        ? this.$t('user', 'Спасибо! Ваш профиль полностью активирован.')
        : this.$t('user', 'Данные вашего профиля успешно сохранены.'))
      if (data['mobile_phone'] !== this.$store.state.user.mobile_phone) {
        this.resetVerifying()
      }
      for (let attr in this.form.model) {
        if (attr !== 'manager_data') {
          this.$store.commit('user/updateDetails', [attr, data[attr]])
        }
      }
    }
  },

  validations () {
    let webUrl = /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9-_]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
    // let mobileRegMin = /^[0-9]{10}$/
    let edprouReg = /^[0-9]{8}$/
    let innReg = /^[0-9]{10}$/
    let mobileRegFull = /^\+38 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/
    const mustBeReg = (param) => (value) => !value || param.test(value)
    // const mustBeVerified = () => this.form.model.mobile_phone_verified
    return {
      form: {
        model: {
          first_name: {
            maxLength: maxLength(255)
          },
          middle_name: {
            maxLength: maxLength(255)
          },
          last_name: {
            required,
            maxLength: maxLength(255)
          },
          company: {
            required,
            maxLength: maxLength(255)
          },
          phone: {
            phone: mustBeReg(mobileRegFull),
            maxLength: maxLength(255)
          },
          mobile_phone: {
            phone: mustBeReg(mobileRegFull),
            required,
            // verified: mustBeVerified,
            maxLength: maxLength(255)
          },
          business_id: {
            numeric,
            required,
            format: mustBeReg(this.form.model.business_type === 'company' ? edprouReg : innReg)
          },
          address: {
            maxLength: maxLength(255)
          },
          website: {
            url_be: mustBeReg(webUrl),
            maxLength: maxLength(255)
          }
        }
      }
    }
  },
  computed: {
    user () {
      return this.$store.state.user
    },
    userInitialized () {
      return this.$store.getters['user/isInitialized']
    },
    numberToTime () {
      let time = this.form.phoneVerification.remaining
      if (time < 0) {
        time = 0
      }
      if (time !== null) {
        let minutes = Math.floor(time / 60)
        minutes = minutes < 10 ? '0' + minutes : minutes
        let seconds = time % 60
        seconds = seconds < 10 ? '0' + seconds : seconds
        return minutes + ':' + seconds
      }
      return false
    }
  }
}
</script>
<style lang="scss">
.verified{
  font-size: 35px;
}
.phone_span{
  white-space: nowrap;
  color: #20a8d8;
  font-weight: 600;
}
.ask_sms_desk{
  border: 1px solid #20a8d8;
  padding: 3px 10px;
  border-radius: 5px;
}
.send_again_link{
  background: none;
  border: none;
  color: #20a8d8 ;
  cursor: pointer ;
  text-decoration: underline;
  &:disabled{
    opacity: 0.4;
    cursor: not-allowed;
  }
}
</style>

import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import qs from 'qs'

// Containers
import DefaultContainer from '@/containers/DefaultContainer'

// Views
// import Dashboard from '@/views/Dashboard'
import Profile from '@/views/Profile'
import Manager from '@/views/Manager'

// Subroutes
import Projects from './Projects'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  parseQuery: query => qs.parse(query),
  stringifyQuery: query => {
    var result = qs.stringify(query)
    return result ? ('?' + result) : ''
  },
  routes: [
    {
      path: '/',
      redirect: '/projects',
      meta: {
        hideBreadcrumb: true
      },
      component: DefaultContainer,
      children: [
        // {
        //   path: 'dashboard',
        //   name: 'Dashboard',
        //   component: Dashboard
        // },
        {
          path: 'profile',
          name: 'profile',
          component: Profile,
          meta: {
            label: 'Профиль'
          }
        },
        {
          path: 'manager',
          name: 'manager',
          component: Manager,
          meta: {
            label: 'Менеджер'
          }
        },
        Projects
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  // redirect to init form in profile if user not initialized
  if (to.name !== 'profile' && store.state.user.isAuthenticated && !store.getters['user/isInitialized']) {
    next('profile')
  } else {
    next()
  }
})

export default router

import {
} from '@fortawesome/free-regular-svg-icons'

import {
  faUser,
  faUserTie,
  faUsers,
  faPowerOff,
  faCubes,
  faCube,
  faTrashAlt,
  faDownload,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons'

export default {
  faUser,
  faUserTie,
  faUsers,
  faPowerOff,
  faCubes,
  faCube,
  faTrashAlt,
  faDownload,
  faExclamationTriangle
}

<template>
  <div class="animated fadeIn">
    <dl class="row">
      <dt class="col-xl-4">
        <!-- accept specification action -->
        <b-button
            v-if="['specification_granted', 'sent', 'designing'].includes(model.status)"
            :disabled="returnedIds.includes(file.id)"
            variant="primary"
            style="padding-left: 30px;padding-right: 30px;"
            @click="updateStatus('specification_accepted')">{{ $t('project', 'Принять') }}</b-button>
        <span v-else-if="(model.status === 'specification_accepted') && (file.status === 3)">{{ $t('project', 'Принята') }}</span>
        <span v-else-if="(model.status === 'specification_accepted') && (file.status !== 3)">{{'\xa0'}}</span>
      </dt>
      <dd class="col-xl-4">
        <!-- deny specification action -->
        <b-button
            v-if="['specification_granted', 'sent', 'designing'].includes(model.status)"
            :disabled="disableBtnDeny"
            variant="outline-primary"
            style="padding-left: 30px;padding-right: 30px;"
            @click="returnSpecification">{{ $t('project', 'Отклонить') }}</b-button>
      </dd>
    </dl>
    <!-- deny specification modal -->
    <b-modal
        v-if="['specification_granted', 'sent', 'designing'].includes(model.status)"
        :title="$t('project', 'Вернуть спецификацию на доработку')"
        class="modal-primary"
        hide-footer
        v-model="showReturnSpecificationForm">
      <return-specification-form :model="model" :file="file" @specification-returned="afterSpecificationReturned"></return-specification-form>
    </b-modal>
  </div>
</template>

<script>
import ReturnSpecificationForm from './handler/ReturnSpecificationForm'
import { LoadingRequestMixin } from '@/mixins'

export default {
  name: 'specification-handler',
  mixins: [LoadingRequestMixin],
  components: {
    ReturnSpecificationForm
  },
  props: ['model', 'file', 'isLastReturnedSpecification', 'returnedIds'],
  data () {
    return {
      showReturnSpecificationForm: false,
      disableBtnDeny: false
    }
  },
  mounted () {
    this.checkDisableBtns()
  },
  methods: {
    async updateStatus (status) {
      let successMessage = ''
      if (status === 'specification_accepted') {
        successMessage = this.$t('project', 'Спецификация проекта была успешно принята.')
      } else if (['sent', 'designing'].includes(status)) {
        successMessage = this.$t('project', 'Спецификация возвращена на доработку.')
      }

      // update
      const requestName = 'updateStatus.' + status
      if (this.isLoadingRequest(requestName)) {
        return
      }
      try {
        this.startLoadingRequest(requestName)
        const response = await this.$http.patch('customer/projects/' + this.model.id + '/status', {
          status: status,
          specification_id: this.file.id
        })
        this.stopLoadingRequest(requestName)
        if (response.data) {
          this.$emit('updated', [
            { attribute: 'status', value: response.data.status },
            { attribute: 'specifications', value: response.data.specifications }
          ])
          this.$notifySuccess(successMessage)
        }
      } catch (e) {
        this.stopLoadingRequest(requestName)
        this.$notifyError(this.$t('project', 'Не удалось изменить статус проекта. ') + e.message)
      }
    },
    returnSpecification () {
      if (this.isLastReturnedSpecification) {
        this.showReturnSpecificationForm = true
      } else {
        let status = this.model.designer ? 'designing' : 'sent'
        this.updateStatus(status)
        this.disableBtnDeny = true
      }
    },
    afterSpecificationReturned (e) {
      this.$emit('updated', e)
      this.showReturnSpecificationForm = false
      this.disableBtnDeny = true
    },
    checkDisableBtns () {
      if (this.file.status === 2) {
        this.disableBtnDeny = true
      }
    }
  }
}
</script>
<style>
</style>

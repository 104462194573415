<template>
  <div class="animated fadeIn">
    <!-- send action -->
    <span id="manager_not_assigned" class="" tabindex="0">
        <b-button
          v-if="['created', 'returned'].includes(model.status)"
          :disabled="!model.manager_assigned"
          variant="primary"
          block
          class="send_project_button mb-2"
          @click="updateStatus('sent')">{{ $t('project', 'Отправить проект') }}</b-button>
    </span>
    <b-tooltip v-if="!model.manager_assigned" target="manager_not_assigned" triggers="hover">
      {{ $t('project', 'Менеджер не назначен') }}
    </b-tooltip>
     <!-- remove action -->
     <b-button
            v-show="model.status === 'created'"
            variant="danger"
            block
            @click="remove()">{{ $t('project', 'Удалить проект') }}</b-button>
    <b-button
      v-show="model.status === 'sent'"
      variant="primary"
      block
      @click="updateProjectIn1C()"
      >{{ $t('project', 'Обновить Проект') }}</b-button>

    <!-- cancel action -->
    <b-button
          v-if="canCancel"
          variant="danger"
          block
          @click="showCancelForm = true">{{ $t('project', 'Отозвать проект') }}</b-button>

    <!-- cancel modal -->
    <b-modal
          v-if="canCancel"
          :title="$t('project', 'Отозвать проект')"
          class="modal-danger"
          hide-footer
          v-model="showCancelForm">
      <cancel-form :model="model" @canceled="afterCanceled"></cancel-form>
    </b-modal>
  </div>
</template>

<script>
import CancelForm from './handler/CancelForm'
import { LoadingRequestMixin } from '@/mixins'

export default {
  name: 'project-handler',
  mixins: [LoadingRequestMixin],
  components: {
    CancelForm
  },
  props: ['model'],
  data () {
    return {
      showCancelForm: false
    }
  },
  computed: {
    canCancel () {
      return !['created', 'canceled', 'rejected', 'finished'].includes(this.model.status)
    }
  },
  methods: {
    async updateStatus (status) {
      let successMessage = ''
      switch (status) {
        case 'sent':
          // check integrity
          const missing = []
          if (!this.model.title) {
            missing.push(this.$t('project', 'название проекта'))
          }
          if (!this.model.date) {
            missing.push(this.$t('project', 'срок сдачи проекта'))
          }
          if (!this.model.coordinates) {
            missing.push(this.$t('project', 'место установки'))
          }
          if (!this.model.client) {
            missing.push(this.$t('project', 'заказчик'))
          }
          if (missing.length > 0) {
            this.$notifyError(this.$t('project', 'Проект не был отправлен. Необходимо заполнить обязательные поля: ') + missing.join(', ') + '.')
            return false
          }
          successMessage = this.$t('project', 'Проект был успешно отправлен. Ожидайте новых уведомлений.')
          break
      }

      // update
      const requestName = 'updateStatus.' + status
      if (this.isLoadingRequest(requestName)) {
        return
      }
      try {
        this.startLoadingRequest(requestName)
        const response = await this.$http.patch('customer/projects/' + this.model.id + '/status', {
          status
        })
        this.stopLoadingRequest(requestName)
        if (response.data) {
          this.$emit('updated', [{ attribute: 'status', value: response.data.status }])
          this.$notifySuccess(successMessage)
        }
      } catch (e) {
        this.stopLoadingRequest(requestName)
        this.$notifyError(this.$t('project', 'Не удалось изменить статус проекта. ') + e.message)
      }
    },
    async updateProjectIn1C () {
      const successMessage = this.$t('project', 'Проект был успешно отправлен в 1с.')
      const requestName = 'updateProject'
      if (this.isLoadingRequest(requestName)) {
        return
      }
      try {
        this.startLoadingRequest(requestName)
        const response = await this.$http.patch('customer/projects/' + this.model.id + '/update-onec')
        this.stopLoadingRequest(requestName)
        if (response.data) {
          if (response.data.status === 'success') {
            this.$notifySuccess(successMessage)
          } else {
            this.$notifyError(this.$t('project', 'Не удалось обновить проект в 1с.Error code ') + response.data.error)
          }
        }
      } catch (e) {
        this.stopLoadingRequest(requestName)
        this.$notifyError(this.$t('project', 'Не удалось обновить проект в 1с.') + e.message)
      }
    },
    async remove () {
      if (this.isLoadingRequest('project.remove')) {
        return
      }
      try {
        this.startLoadingRequest('project.remove')
        const response = await this.$http.delete('customer/projects/' + this.model.id)
        this.stopLoadingRequest('project.remove')
        if (response.status === 204) {
          this.$notifySuccess(this.$t('project', 'Проект был успешно удален.'))
          this.$router.push({ name: 'projects' })
        }
      } catch (e) {
        this.stopLoadingRequest('project.remove')
        this.$notifyError(this.$t('project', 'Не удалось удалить проект. ') + e.message)
      }
    },
    afterCanceled (e) {
      this.$emit('updated', e)
      this.showCancelForm = false
    }
  }
}
</script>
<style>
.send_project_button:disabled{
  color: #fff;
  background-color: #6c7173;
  border-color: #e0e0e0;
}
</style>

<template>
  <div class="animated fadeIn">
    <div class="row">
      <div class="col-xl-3 col-lg-4 col-md-5 order-md-last p-4">
        <!-- project actions -->
        <handler :model="model" @updated="$emit('updated', $event)"></handler>
      </div>

      <!-- user details -->
      <div class="col-xl-9 col-lg-8 col-md-7 order-md-first">
        <dl class="row">
          <dt class="col-xl-3">ID</dt>
          <dd class="col-xl-9">{{ model.id }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Название') }}</dt>
          <dd class="col-xl-9">{{ model.title }}</dd>

          <dt v-if="model.status_message" class="col-xl-3">
            {{ $t('project', 'Сообщение при изменении статуса') }}
            <font-awesome-icon icon="exclamation-triangle" class="text-danger ml-1"></font-awesome-icon>
          </dt>
          <dd v-if="model.status_message" class="col-xl-9">
            {{ model.status_message }}
          </dd>

          <dt class="col-xl-3">{{ $t('project', 'Срок сдачи проекта') }}</dt>
          <dd class="col-xl-9">{{ toDate(model.date, false) }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Адрес') }}</dt>
          <dd class="col-xl-9">{{ model.address }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Координаты') }}</dt>
          <dd class="col-xl-9">
            {{ model.coordinates }}
            <b-link v-if="model.coordinates" href="#" @click="showMap = true" class="ml-2">{{ $t('project', 'Карта') }}</b-link>
            <b-modal
                   v-if="model.coordinates"
                  :title="$t('project', 'Местонахождение обьекта')"
                  class="modal-primary"
                  hide-footer
                  v-model="showMap">
              <embed-map :marker="model.coordinates"></embed-map>
            </b-modal>
          </dd>

          <dt class="col-xl-3">{{ $t('project', 'Заказчик') }}</dt>
          <dd class="col-xl-9">{{ model.client }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Ссылка на тендер или аукцион') }}</dt>
          <dd class="col-xl-9"><a :href="model.auction_link" target="_blank">{{ model.auction_link }}</a></dd>

          <dt v-if="model.specifications.length > 0" class="col-xl-3">
            Спецификация
            <font-awesome-icon v-if="model.status === 'specification_granted'" icon="exclamation-triangle" class="text-danger ml-1"></font-awesome-icon>
          </dt>
          <dd v-if="model.specifications.length > 0" class="col-xl-9">
            <dl class="row">
              <template v-for="(file, key) in model.specifications">
                <dt class="col-xl-4" :key="key">
                  <a download :href="'/api/customer/projects/' + model.id + '/files/' + file.id" :key="key" v-if="allSpecificationIds.includes(file.id)">
                    {{ file.filename }}
                  </a>
                  <span v-else>{{'\xa0'}}</span>
                </dt>
                <dd class="col-xl-2" :key="key">
                  <span v-if="allSpecificationIds.includes(file.id)">Версия: {{ file.version }}</span>
                </dd>
                <dd class="col-xl-6" :key="key">
                  <specification-handler
                      :model="model"
                      :file="file"
                      :key="key"
                      :isLastReturnedSpecification="isLastReturnedSpecification"
                      :returnedIds="returnedIds"
                      v-if="(allSpecificationIds.includes(file.id) && file.status !== 0) || (model.status === 'specification_granted' && file.status === 0)"
                      @updated="updateStatus"
                  >
                  </specification-handler>
                </dd>
              </template>
            </dl>
          </dd>

          <dt class="col-xl-3">{{ $t('project', 'Техническое задание') }}</dt>
          <dd class="col-xl-9">
            <a v-if="model.technical_task" download :href="'/api/customer/projects/' + model.id + '/files/' + model.technical_task.id">
              {{ model.technical_task.filename }}
            </a>
          </dd>

          <dt class="col-xl-3">{{ $t('project', 'Другие файлы') }}</dt>
          <dd class="col-xl-9">
            <template v-for="(file, key) in model.other_files">
              <a download :href="'/api/customer/projects/' + model.id + '/files/' + file.id" :key="key">
                {{ file.filename }}
              </a>
              <template v-if="key < model.other_files.length - 1">, </template>
            </template>
          </dd>

          <dt class="col-xl-3">{{ $t('project', 'Условия поставки') }}</dt>
          <dd class="col-xl-9"><multi-line :text="model.delivery_conditions"></multi-line></dd>

          <dt class="col-xl-3">{{ $t('project', 'Субподрядчик') }}</dt>
          <dd class="col-xl-9">{{ model.subcontractor }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Описание проекта') }}</dt>
          <dd class="col-xl-9"><multi-line :text="model.revision_description"></multi-line></dd>

          <dt class="col-xl-3">{{ $t('project', 'Описание перспектив и доработок') }}</dt>
          <dd class="col-xl-9"><multi-line :text="model.development_prospects"></multi-line></dd>

          <dt class="col-xl-3">{{ $t('project', 'Дата создания') }}</dt>
          <dd class="col-xl-9">{{ toDate(model.created_at) }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Дата последнего изменения') }}</dt>
          <dd class="col-xl-9">{{ toDate(model.updated_at) }}</dd>

          <dt class="col-xl-3">{{ $t('project', 'Комментарии') }}</dt>
          <dd class="col-xl-9">
            <p v-for="(comment, i) in model.comments" :key="i">
              {{ toDate(comment.created_at) }}
              <br>
              {{ (comment.author.first_name + ' ' + comment.author.last_name).trim() }}
              <br>
              <multi-line :text="comment.body"></multi-line>
            </p>
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
import { DateHelper } from '@/helpers'
import { MultiLine, EmbedMap } from '@/components'
import Handler from './view/Handler'
import SpecificationHandler from './view/SpecificationHandler'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'project-view',
  components: {
    MultiLine, EmbedMap, Handler, SpecificationHandler, FontAwesomeIcon
  },
  props: {
    model: {
      type: Object,
      required: true
    }
  },
  mounted () {
    this.checkLastReturnedSpecification(this.model.specifications)
  },
  data () {
    return {
      showMap: false,
      isLastReturnedSpecification: true,
      returnedIds: [],
      allSpecificationIds: []
    }
  },
  methods: {
    toDate (timestamp, hM = true) {
      return DateHelper.toHumanStr(timestamp, hM)
    },
    updateStatus (e) {
      this.$emit('updated', e)
      this.checkLastReturnedSpecification(e[1].value)
    },
    checkLastReturnedSpecification (specifications) {
      let countSpecificationsReturned = 0
      specifications.forEach((el) => {
        if (el.status === 2) {
          countSpecificationsReturned += 1
          this.returnedIds.push(el.id)
        }
        this.allSpecificationIds.push(el.id)
      })
      this.isLastReturnedSpecification = countSpecificationsReturned >= (specifications.length - 1)
    }
  }
}
</script>

<template>
  <img src="../images/dev_logo.png" alt="..." width="30" height="30">
</template>

<script>
export default {
  name: 'dev-logo'
}

</script>

<style lang="scss">
.dev-logo {
  .st0{display:none;fill:#FFFFFF;}
  .st1{fill:#616161;}
  .st2{fill:#414141;}
  &:hover {
    .st1{fill:#18662b;}
    .st2{fill:#289b44;}
  }
}
</style>

export default {
  required: 'Значение поля не может быть пустым.',
  numeric: 'Значение поля должно быть числом.',
  email: 'Значение поля должно содержать валидный электронный адрес.',
  fileType: 'Принимаются только файлы типов {allowedTypes}.',
  fileExtension: 'Принимаются только файлы с расширениями {allowedExtensions}.',
  fileSize: 'Суммарный объем файлов не может превышать {allowedSize}MB.',
  filesNumber: 'Количество загружаемых файлов не может превышать {allowedNumber}.',
  coordinates: 'Значение поля должно содержать только ширину и долготу, разделенные запятой, например "xx.xxxx,-yy.yyyy".',
  url: 'Значение поля должно содержать только один URL.',
  url_be: 'Значение Значение поля должно являться URL.',
  format: 'Значение поля неправильного формата либо длины',
  guid: 'Вы должны привязать guid пользователя',
  verified: 'Значение поля должен быть верифицирован',
  maxLength: 'Значение поля должно содержать не более {max} символов.',
  minLength: 'Значение поля должно содержать не менее {min} символов.',
  phone: 'Значение поля должно содержать только цифры, дефис и пробел.'
}

import Vue from 'vue'
import Vuex from 'vuex'
import { app, http, page } from '@/../../shared/store/modules'
import { user } from './modules'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    http,
    user,
    page
  }
})

<template>
  <div class="animated fadeIn">
    <div class="card">
      <div class="card-body">
        <!-- Buttons block -->
        <div class="d-flex justify-content-between flex-wrap mb-4">
          <div class="d-flex justify-content-between" style="width: 350px;">
            <b-button class="mt-1" variant="primary" @click="addProject">{{ $t('project', 'Добавить проект') }}</b-button>
            <b-button
                v-if="this.$store.state.user.provider === 'viatec'"
                class="mt-1"
                variant="outline-primary"
                @click="showFaqInfo = true">
              {{ $t('project', 'FAQ - Что такое проект?') }}
            </b-button>
            <b-modal
                v-if="this.$store.state.user.provider === 'viatec'"
                :title="$t('project', 'FAQ - Что такое проект?')"
                class="modal-primary"
                size="xl"
                ok-only
                scrollable
                lazy
                v-model="showFaqInfo">
              <faq :info="faqInfo"></faq>
            </b-modal>
          </div>

          <b-button class="mt-1" v-if="!filter.isOpen" variant="outline-primary" @click="toggleFilter" key="openF">{{ $t('project', 'Открыть фильтр') }}</b-button>
          <b-button class="mt-1" v-else variant="outline-primary" @click="toggleFilter" key="closeF">{{ $t('project', 'Закрыть фильтр') }}</b-button>

        </div>

        <!-- Filter -->
        <div class="index-filter" v-if="filter.isOpen">
          <div class="row my-3">
            <div class="col-6 col-md-4 col-xl-3">
              <form-input
                    name="id"
                    :label="$t('project', 'ID проекта')"
                    :value="filter.model.id"
                    @change="filterChanged('id', $event)"></form-input>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
              <form-input
                    name="title"
                    :label="$t('project', 'Название проекта')"
                    :value="filter.model.title"
                    @change="filterChanged('title', $event)"></form-input>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
              <form-date-picker
                    name="date"
                    :label="$t('project', 'Срок сдачи проекта')"
                    range
                    timestamp
                    :value="filter.model.date"
                    @change="filterChanged('date', $event)"></form-date-picker>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
              <form-date-picker
                    name="created_at"
                    :label="$t('project', 'Дата создания')"
                    range
                    timestamp
                    :value="filter.model.created_at"
                    @change="filterChanged('created_at', $event)"></form-date-picker>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
              <form-date-picker
                    name="updated_at"
                    :label="$t('project', 'Дата обновления')"
                    range
                    timestamp
                    :value="filter.model.updated_at"
                    @change="filterChanged('updated_at', $event)"></form-date-picker>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
              <form-select
                    name="status"
                    :label="$t('project', 'Статус')"
                    :value="filter.model.status"
                    :options="projectStatuses"
                    allow-empty
                    @change="filterChanged('status', $event)"></form-select>
            </div>
            <div class="col-6 col-md-4 col-xl-3">
              <form-input
                    name="client"
                    :label="$t('project', 'Заказчик')"
                    :value="filter.model.client"
                    @change="filterChanged('client', $event)"></form-input>
            </div>
          </div>
        </div>

        <!-- Items grid -->
        <data-grid
              :fields="gridFields"
              :data-slots="grid.slots"
              service="customer/projects"
              no-actions
              :filter="filter">
          <template #status="{ item }">{{ $t('project', $params.project.statuses[item.status]) }}</template>
          <template #title="{ item }">
            <router-link
              v-if="shouldBeTruncated(item.title)"
              :to="{ name: 'project/details', params: { id: item.id } }"
              v-b-tooltip
              :title="item.title"
            >
              {{ truncate(item.title) }}
            </router-link>
            <router-link
              v-else
              :to="{ name: 'project/details', params: { id: item.id } }"
            >
              {{ item.title }}
            </router-link>
          </template>
          <template #created_at="{ item }">{{ timeToHuman(item.created_at) }}</template>
          <template #updated_at="{ item }">{{ timeToHuman(item.updated_at) }}</template>
          <template #date="{ item }">
            <font-awesome-icon
                  v-if="showDeadlineAlert(item.date)"
                  icon="exclamation-triangle"
                  class="text-danger"
                  v-b-tooltip.hover.bottom="$t('project', 'Меньше 10 дней до дедлайна')"></font-awesome-icon>
            {{ timeToHuman(item.date) }}
          </template>
        </data-grid>
      </div>
    </div>

    <!-- Form modal -->
    <b-modal
          :title="$t('project', 'Добавить новый проект')"
          class="modal-primary"
          v-model="form.show"
          hide-footer
          @shown="$event.target.querySelector('input').focus()">
      <form @submit="onSubmit">
        <form-input
              name="title"
              :label="$t('project', 'Название проекта')"
              :hint="$t('project', 'Вы сможете изменить название позже.')"
              :error-message="form.errors.title"
              @change="onChange('title', $event)"
              @input="onInput('title', $event)"></form-input>
        <form-checkbox
            name="faq"
            :label="$t('project', 'Условия использования проекта')"
            @change="afterReadFaqInfo"></form-checkbox>

        <button type="submit" class="btn btn-primary" :disabled="!isEnableBtnSave">{{ $t('service', 'Сохранить') }}</button>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { DataGrid, FormInput, FormSelect, FormDatePicker, FormCheckbox } from '@/components'
import { DateHelper, StringHelper } from '@/helpers'
import { FilterMixin, FormMixin } from '@/mixins'
import { required } from '@/validators'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import Faq from './index/Faq'

const TITLE_MAX_WORDS = 5

export default {
  name: 'index',
  mixins: [
    FilterMixin, FormMixin
  ],
  components: {
    DataGrid, FormInput, FormDatePicker, FontAwesomeIcon, FormSelect, Faq, FormCheckbox
  },
  data () {
    return {
      showFaqInfo: false,
      isEnableBtnSave: false,
      form: {
        model: {
          title: null
        },
        service: 'customer/projects',
        afterSave: (data) => {
          this.$router.push({ name: 'project/edit', params: { id: data.id } })
        },
        show: false
      },

      filter: {
        model: {
          id: null,
          title: null,
          date: null,
          client: null,
          status: null,
          created_at: null,
          updated_at: null
        },
        conditions: {
          title: this.$const.CONDITIONS.LIKE,
          date: [this.$const.CONDITIONS.GREATER_THAN_EQUAL, this.$const.CONDITIONS.LESS_THAN_EQUAL],
          client: this.$const.CONDITIONS.LIKE,
          created_at: [this.$const.CONDITIONS.GREATER_THAN_EQUAL, this.$const.CONDITIONS.LESS_THAN_EQUAL],
          updated_at: [this.$const.CONDITIONS.GREATER_THAN_EQUAL, this.$const.CONDITIONS.LESS_THAN_EQUAL]
        }
      },

      grid: {
        fields: {
          id: {
            sortable: true,
            label: 'ID'
          },
          title: {
            sortable: true,
            label: 'Название'
          },
          date: {
            sortable: true,
            label: 'Срок сдачи',
            class: 'd-none d-lg-table-cell'
          },
          client: {
            sortable: true,
            label: 'Заказчик',
            class: 'd-none d-xl-table-cell'
          },
          status: {
            sortable: true,
            label: 'Статус',
            class: 'd-none d-md-table-cell'
          },
          created_at: {
            sortable: true,
            label: 'Дата создания',
            class: 'd-none d-xl-table-cell'
          },
          updated_at: {
            sortable: true,
            label: 'Дата обновления',
            class: 'd-none d-xl-table-cell'
          }
        },
        slots: ['date', 'status', 'created_at', 'updated_at', 'title']
      },
      faqInfo: []
    }
  },
  validations () {
    return {
      form: {
        model: {
          title: {
            required
          }
        }
      }
    }
  },
  created () {
    this.getFaqInfo()
  },
  computed: {
    projectStatuses () {
      const result = {}
      Object.entries(this.$params.project.statuses).forEach(([key, value]) => {
        result[key] = this.$t('project', value)
      })
      return result
    },
    gridFields () {
      const result = []
      Object.entries(this.grid.fields).forEach(([key, value]) => {
        value.label = this.$t('project', value.label)
        result[key] = value
      })
      return result
    }
  },
  methods: {
    timeToHuman (time) {
      return DateHelper.toHumanStr(time)
    },
    showDeadlineAlert (deadlineTimestamp) {
      const diff = deadlineTimestamp - Math.floor(Date.now() / 1000)
      return diff > 0 && diff < 60 * 60 * 24 * 10
    },
    shouldBeTruncated (str, maxWords = TITLE_MAX_WORDS) {
      return str.split(' ').length > maxWords
    },
    truncate (str, words = TITLE_MAX_WORDS) {
      return StringHelper.truncateWords(str, words)
    },
    async getFaqInfo () {
      try {
        const response = await this.$http.get('customer/projects/faq-info/1')
        if (response.data) {
          this.faqInfo = response.data
        }
      } catch (e) {
        this.$notifyError(this.$t('project', 'Не удалось получить FAQ информацию.') + ' ' + e.message)
      }
    },
    afterReadFaqInfo () {
      this.showFaqInfo = true
      this.isEnableBtnSave = true
    },
    addProject () {
      this.form.show = true
      this.isEnableBtnSave = false
    }
  }
}
</script>
